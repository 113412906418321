/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';


 .CheckoutOrderSummary {
    $divider-border-color: #e0e0e0;
    $divider-border: solid 1px $divider-border-color;

    &-Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
    }

    &-ItemsInCart {
        font-size: 1.2rem;
        opacity: .5;
        text-transform: none;
        top: 2px;
        font-weight: 300;
    }

    &-SummaryItem {
        justify-content: space-between;
        display: flex;
        padding: 1.2rem 0;
        margin-bottom: 0;

        @include mobile {
            padding: 1.4rem 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: none;
        }

        &_withAppendedContent {
            flex-wrap: wrap;
        }
    }

    &-OrderItems {
        @include mobile {
            background-color: var(--secondary-base-color);
        }

        h3 {
            text-align: left;
        }
    }

    &-CartItemList {
        list-style: none;
        margin: 0;
        padding: 0;

        @include mobile {
            --cart-item-background: var(--secondary-base-color);
            --cart-item-divider-color: $divider-border-color;
        }
    }

    &-CartItemDescription {
        margin-top: 5px;

        p {
            font-size: 1.32rem;
            line-height: 1.5;

            @include mobile {
                font-size: 1.54rem;
            }
        }
    }

    &-CartItem {
        display: grid;
        grid-template-columns: 9.6rem auto auto;
        grid-column-gap: .6rem;
        margin: 0 0 .6rem;
        padding: 0;

        @include mobile {
            grid-template-columns: 11.2rem auto auto;
            grid-column-gap: .7rem;
            margin: 0 0 .7rem;
        }

        &::before {
            content: none;
        }

        > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-Thumbnail {
        width: 9.6rem;
        height: 9.6rem;

        @include mobile {
            width: 11.2rem;
            height: 11.2rem;
        }
    }

    &-Title {
        p {
            margin: 0;
            padding: 0;
            text-align: left;
        }
    }

    &-Details {
        justify-self: end;
    }

    &-ExpandableContent {
        margin: 0 -1.4rem;
        background-color: var(--secondary-base-color);
    }

    &-Coupon {
        @include mobile {
            padding: 10px 1.4rem;
            border-bottom: $divider-border;
            margin: 0 -1.4rem;
            width: auto;
        }
    }

    &-CmsBlock {
        padding: 10px 0;
        border-bottom: $divider-border;
    }

    .ProductPrice {
        margin-top: 0;
        font-weight: bold;
        line-height: 1;
        font-size: 1.4rem;

        &-SubPrice {
            display: block;
            font-size: .7em;
            font-weight: 300;
        }
    }

    .CartItem-Wrapper {
        padding: 1.2rem 0;

        @include mobile {
            padding: 1.4rem 0;
        }
    }

    &-Text {
        text-align: right;

        span {
            display: block;
            font-size: .7em;
            font-weight: 300;
        }
    }

    &-AppendedContent {
        flex-basis: 100%;
    }
}
.DeliveryDateSummary{
    &-Title{
        border-bottom: 1px solid var(--cart-item-divider-color);
        font-size: 1.8rem;
        padding: 10px 0;
        margin-bottom: 15px;
        @include mobile {
            border-color:#e0e0e0;
        }
    }
    &-Content{
        line-height: 1.8;
        .label{
            padding-right: 6px;
        }
    }
}