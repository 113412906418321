/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckoutShipping {
    .Checkout-StickyButtonWrapper {
        margin-top: 20px;
    }
    .Field{
        textarea{
            width: 100%;
        }
    }
}

.DateFieldCol{
    label {
        font-weight: 700;
        padding-bottom: .36rem;
    }
    .duet-date__dialog-content{
        background: #fff;
    }
    .duet-date__dialog{
        &.is-active{
            z-index: 3;
        }
    }
    .duet-date__toggle{
        &:focus{
            box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
        }
    } 
    .duet-date__input{
        border: 1px solid var(--input-border-color);
        font-size: small;
        pointer-events: none;
    }

}
.CheckoutAddressForm{
    .AmDeliveryDateCityDisable{
        display: none;
    }
    .AmDeliveryDateCountryIdDisable{
        display: none;
    }
}
.Fieldnote{
    &-Message{
        font-size: 1.26rem;
        font-weight: 600;
        margin: 0.8rem 0;
        display: block;
    }
}
.CheckoutAdditional-fieldset{
    &.FieldForm{
        .Field-Message{
            margin: 0.8rem 0;
            display: block;
        }
    }
}
.CheckoutAddressForm{
   .AmDeliverySurbDisable{
        display: none;
    }
}