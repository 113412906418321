/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ReviewPagination{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin: auto;
    -webkit-align-items: center;
    align-items: center;
    z-index: 2;
    width: -webkit-min-content;
    width: min-content;
    &-ListItem{
        display: inline-block;
        list-style: none;
        margin: 0 2px;
        padding: 8px 11px;
        color: var(--color-pagination-link-text);
        cursor: pointer;
        &:before{
            display: none;
        }
        &.hide{
            display: none;
        }
        &.active{
            background-color: var(--secondary-base-color);
            cursor: default;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:hover{
            --color-pagination-link-text: var(--color-pagination-link-active-text);
        }
        &.Next,
        &.Prev{
            padding: 0;
        }
    }
    
    &-Arrow{
        cursor: pointer;
        margin: 0;
        height: 32px;
        display: block;
        width: 32px;
        position: relative;
        font-size: 0;
        &:before{
            content: "";
            position: absolute;
            width: 16px;
            height: 1px;
            top: 16px;
            right: 8px;
            background-color: var(--header-color);
        }
        &:after{
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 12px;
            left: 10px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            border-top: 1px solid var(--header-color);
            border-left: 1px solid var(--header-color);
        }
    }
    &-ListItem.Next{
        .ReviewPagination-Arrow{
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
    
}