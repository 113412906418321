/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.data-grid-cell-content.summary {
    font-weight  : 600;
    font-size    : 15px;
    margin-bottom: 8px;
    line-height  : 1.4;
}
.data-grid-cell-content.date {
    font-weight: 400;
    font-size  : 13px;
    color      : #707070;
    float      : right;
    margin-top: 6px;
}

.data-grid-cell-content.rating-list {
    float: left;
}

.data-grid-cell-content.review-data {
    width      : 100%;
    display: inline-block;
    margin-top : 20px;
    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;
}
.input-text {
    width: 100%;
}
.review-items {
    margin-bottom: 50px;
    display: inline-block;
    width: 100%;
    @include mobile {
        width: 100%;
        float: left;
    }
    .review-list-column-main{
        @include desktop {
            width: 100%;
            max-width: calc(100% - 290px);
            float: left;
        }
    }
}

.review-list-sidebar-main {
    min-width   : 240px;
    margin-right: 50px;
    text-align  : right;
    float       : left;
    max-width   : 240px;
    @include mobile {
        min-width    : 100%;
        margin-right : 0;
        text-align   : left;
        max-width    : 100%;
        width        : 100%;
        margin-bottom: 20px;
    }

    .data-grid-cell-content {
        margin-top: 7px;
    }

    .recommend {
        display     : inline-block;
        color       : #4BB543;
        position    : relative;
        padding-left: 6px;
        margin-top  : 7px;
        max-width   : 80px;
        line-height : 15px;
        @include mobile {
            padding-left: 25px;
            max-width   : 100%;
        }

        &::before {
            content          : '';
            color            : #fff;
            position         : absolute;
            margin-left      : 3px;
            margin-top       : 4px;
            width            : 6px;
            height           : 3px;
            border-bottom    : solid 2px currentColor;
            border-left      : solid 2px currentColor;
            -webkit-transform: rotate(-45deg);
            transform        : rotate(-45deg);
            left             : -16px;
            top              : 1px;
            z-index          : 2;
            @include mobile {
                content: '';
                left   : 4px;
            }
        }
        &::after {
            content      : '';
            position     : absolute;
            width        : 16px;
            height       : 16px;
            background   : #4BB543;
            border-radius: 50%;
            left         : -17px;
            top          : 0;
            z-index      : 1;
            @include mobile {
                content: '';
                left   : 3px;
            }
        }
        &.dont {
            color    : #e80000;
            max-width: 109px;
            @include mobile {
                max-width: 100%
            }
            &:before {
                display: none;
            }
            &:after {
                content        : "x";
                background     : #e80000;
                color          : #fff;
                display: -ms-flexbox;
                display: flex;
                justify-content: center;
                align-items    : center;
                font-size      : 13px;
                border-radius  : 0;
            }
        }
    }
}
.review-list-column-main {
    @include mobile {
        float: left;
        width: 100%;
    }
}

.comments-block {
    float: left;
    width: 100%;
    textarea{
        width: 100%;
    }
    .comment {
        margin-top: 20px;
        float     : left;
        width     : 100%;
    }
    .admin-comment {
        padding         : 15px;
        background-color: #f6f6f6;
        margin-left     : 30px;
        border-radius   : 3px;
        margin-bottom   : 20px;
        @include mobile {
            margin-left: 0;
        }
    }

    .comment-top-col {
        display        : -ms-flexbox;
        display        : flex;
        -ms-flex-pack  : justify;
        justify-content: space-between;
        margin-bottom  : 10px;
    }

    .nickname {
        font-weight: 600;
        font-size  : 13px;
    }

    .comment-date {
        font-size  : 14px;
        font-weight: 400;
        line-height: 1.5;
        color      : #5a5a5a;
    }

    .add-comment {
        color      : #1979c3;
        font-size  : 16px;
        line-height: 20px;
        margin-top: 15px;
        cursor: pointer;
    }
}

