/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

$pathToSocialLogo: './../../../public/assets/images/login-signup-block/';

.SocialAuthentication {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0 1rem;

    &_isMyAccount {
        flex-direction: column;
    }

    &-Title {
        align-items: center;
        display: inline-flex;
        font-size: var(--color-social-auth-btn-title-mobile);
        padding: 18px 0 19px;
        white-space: nowrap;
        width: 100%;

        @include desktop {
            font-size: var(--color-social-auth-btn-title-desktop);
            padding: 26px 0 28px;
        }

        &::before,
        &::after {
            background: var(--primary-base-color);
            content: '';
            display: block;
            min-height: 1px;
            width: 40%;
        }

        &::after {
            margin-left: 10px;
        }

        &::before {
            margin-right: 10px;
        }

    }

    &-Item {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        .Toggle {
            pointer-events: none;

            &-Label {
                margin: 0 20px 0 0;
            }

            label {
                order: 1;
            }
        }
    }

    &-ToggleWrapper {
        cursor: pointer;
    }

    .Button-Social {
        border: 0;
        height: 50px;
        width: 76px;
        background: {
            color: var(--primary-base-color);
            position: center;
            repeat: no-repeat;
            size: 24px;
        }

        @include desktop {
            background-size: 26px;
            height: 55px;
            width: 55px;
        }

        &_type {
            &_facebook {
                background: {
                    image: url($pathToSocialLogo + 'facebook.svg');
                    size: 30px;
                }

                @include desktop {
                    background-size: 36px;
                }
            }

            &_google {
                background: {
                    image: url($pathToSocialLogo + 'google.svg');
                }

                @include desktop {
                    background-size: 30px;
                }
            }

            &_linkedin {
                background: {
                    image: url($pathToSocialLogo + 'linked-in.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 24px;
                }
            }

            &_paypal {
                background: {
                    image: url($pathToSocialLogo + 'paypal.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 21px;
                }
            }

            &_twitter {
                background: {
                    image: url($pathToSocialLogo + 'twitter.svg');
                    size: 34px;
                }

                @include desktop {
                    background-size: 40px;
                }
            }

            &_instagram {
                background: {
                    image: url($pathToSocialLogo + 'instagram.svg');
                }

                @include desktop {
                    background-size: 28px;
                }
            }

            &_twitch {
                background: {
                    image: url($pathToSocialLogo + 'twitch.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 26px;
                }
            }

            &_amazon {
                background: {
                    image: url('./../../../public/assets/images/login-signup-block/amazon.svg');
                }

                @include desktop {
                    background-size: 30px;
                }
            }
        }
    }
}
