/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@include mobile {
    :root {
        --product-rating-size: 1.8rem;
    }
}
.MyAccountProductReview{
    &-Table {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        .MyAccountProductReviewTableRow{
            .ProductReview-Name{
                color: var(--link-color);
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    td,
    th {
        @include mobile {
            min-width: 0;
        }
    }
    .Sortable-Descend,
    .Sortable-Ascend{
        position: relative;
        cursor: pointer;
        margin-top: -6px;
        .SortableIcon{
            position: absolute;
            right: 2px;
            top: 50%;
            z-index: 1;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    
    .SortableIcon{
        cursor: pointer;
        margin: 0;
        height: 24px;
        display: block;
        width: 20px;
        &:before,
        &:after{
            content: "";
            position: absolute;
        }
        &:before{
            width: 1px;
            height: 14px;
            top: 6px;
            right: 9px;
            background-color: var(--my-account-tab-list-item-color);
        }
        &:after{
            width: 8px;
            height: 8px;
            top: 6px;
            left: 6px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            border-right: 1px solid var(--my-account-tab-list-item-color);
            border-top: 1px solid var(--my-account-tab-list-item-color);
        }
    }
    .Sortable-Ascend{
        .SortableIcon{
            &:after{
                top: auto;
                bottom: 6px;
                border-right: 0;
                border-top: 0;
                border-left: 1px solid var(--my-account-tab-list-item-color);
                border-bottom: 1px solid var(--my-account-tab-list-item-color);
            }
        }
    }
}
.ProductReview{
    &-Notification{
        margin-bottom: 35px;
        .Block-Title{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
            @include mobile {
                font-size: 16px;
            }
        }
        .Field_type_checkbox{
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            > label{
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
                -ms-flex-align: center;
                align-items: center;
            }
            .input-control{
                margin-left: 0;
                margin-right: 10px;
                margin-top: -3px;
                @include mobile {
                    display: table;
                }
            }
        }
    }
    &-Actions{
        margin-top: 20px;
    }
}