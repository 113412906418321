/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductFeedback-Wrapper {
    padding: 0;
    .data.item.content {
        border    : 1px solid #f0f0f0;
        border-top: 0;
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
    }
    .review-main {
        padding: 35px;
    }
    .title {
        font-weight  : 300;
        line-height  : 1.1;
        font-size    : 2.6rem;
        margin-top: 0;
        margin-bottom: 30px;
        text-transform: none;
    }

    .aggregated-rating-absolute {
        font-size   : 50px;
        line-height : 50px;
        font-weight : 400;
        margin-right: 15px;
        display     : inline-block;
    }

    .aw-ar-review-summary {
        display          : -webkit-flex;
        display          : -ms-flexbox;
        display          : flex;
        -webkit-flex-wrap: wrap;
        flex-wrap        : wrap;
    }

    .brief-summary {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .column-summary {
        margin-right          : 25px;
        display               : -webkit-flex;
        display               : -ms-flexbox;
        display               : flex;
        -webkit-flex-direction: column;
        -ms-flex-direction    : column;
        flex-direction        : column;
        &:last-child {
            margin-right: 0;
        }
    }

    .guest-message {
        margin-top: 20px;
        font-size : 14px;
        color     : #333333;
        a {
            margin: 0 5px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .reviews-section {
        font-size     : 15px;
        color         : #333333;
        line-height   : 1.1;
        margin-top    : 5px;
    }
    .recommend-customers {
        margin-top: auto;
        display   : -webkit-flex;
        display   : -ms-flexbox;
        display   : flex;
        color     : #707070;
    }
    .percent {
        font-size   : 33px;
        line-height : 1.1;
        margin-right: 5px;
    }
    .recommend-text {
        font-weight: 400;
        font-style : normal;
        font-size  : 14px;
        line-height: 18px;
        width      : 162px;
    }

    .aw-ar-write-review-control {
        margin-top: auto;
    }

    .rating-bar {
        width           : 125px;
        background-color: #f2f2f2;
        border-radius   : 5px;
        height          : 6px;
        display         : inline-block;
        margin-right    : 5px;
        vertical-align  : middle;
    }

    .progress-bar {
        background-color: #c30017;
        height          : 100%;
        display         : inline-block;
        border-radius   : 5px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
    }

    .rating-value-label {
        line-height : 1.1;
        display     : inline-block;
        margin-right: 7px;
        text-align  : right;
        min-width   : 50px;
    }

    .ratting-value-row {
        display       : -ms-flexbox;
        display       : flex;
        -ms-flex-align: center;
        align-items   : center;
        margin-bottom : 5px;
        line-height   : 18px;
        color       : #333333;
        font-size   : 13px;
        .label {
            margin-right: 4px;
            white-space: nowrap;
            &.rating-value-reviews-count{
                @include mobile {
                    min-width: 12px;
                }
            }
            &.rating-value-reviews-percent{
                @include mobile {
                    min-width: 52px;
                }
            }
        }
    }
}

.review-list {
    border-bottom   : 1px solid #cccccc;
    border-top      : 1px solid #eeeeee;
    background-color: #f8f8f8;
    margin-top: 0;
    .featured-review-title {
        font-weight  : 600;
        line-height  : 1.1;
        font-size    : 1.9rem;
        padding      : 25px 35px 25px;
        border-bottom: 1px solid #eee;
        margin       : 0;
    }

    .review-content {
        background    : #f4f4f4;
        margin        : 0 0 15px 0;
    }
    .review-list-column-main {
        .review-data {
            width      : 100%;
            display: inline-block;
            margin-top : 20px;
            font-weight: 400;
            font-size  : 14px;
            line-height: 20px;
        }
        .summary {
            font-weight  : 600;
            font-size    : 15px;
            margin-bottom: 8px;
            line-height  : 1.4;
        }
        .rating-list {
            float: left;
        }
        .date {
            font-size  : 13px;
            color      : #707070;
            float      : right;
        }
    }
}
.data-grid-filters-wrap {
    padding-left : 287px;
    padding-top  : 20px;
    .filter-options{
        margin-bottom: 20px;
        .FieldSelect{
            @include after-mobile {
                width: 150px;
            }
        }
    }
}
.form-field-control {
    display       : -ms-flexbox;
    display       : flex;
    -ms-flex-align: center;
    align-items   : center;
}

.field-option {
    margin-right : 15px;
    margin-bottom: 25px;
}

.field-option .checkbox {
    margin        : 0 5px 0 0;
    display       : inline-block;
    vertical-align: middle;
}

.option-section {
    position: relative;

    &:before {
        content          : "";
        position         : absolute;
        top              : 20px;
        display          : block;
        height           : 6px;
        width            : 6px;
        border-style     : solid;
        border-width     : 0 0 2px 2px;
        z-index          : 1;
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform        : translate(-50%, -50%) rotate(-45deg);
        right            : 15px;
        pointer-events   : none;
    }
}

.review-sorting {
    .label {
        margin: 0 16px 10px 0;
        &.icon-down,
        &.icon-up {
            margin-right: 28px;
        }
    }
    .sort-item {
        border-bottom: 1px dashed;
        cursor       : pointer;
        &:hover {
            color: #006bb4;
        }
    }
}

.icon-up {
    position: relative;

    &:before {
        content    : "";
        display    : block;
        width      : 4px;
        height     : 4px;
        border-top : 1px solid #000;
        border-left: 1px solid #000;
        transform  : rotate(45deg) translate(0px, 0px);
        position   : absolute;
        right      : -12px;
        top        : 2px;
    }
    &:after {
        content         : "";
        display         : block;
        width           : 1px;
        height          : 8px;
        background-color: black;
        transform       : rotate(0deg) translate(5px, 0px);
        right           : -5px;
        top             : 2px;
        position        : absolute;
    }
}

.icon-down {
    position: relative;
    &:before {
        content    : "";
        display    : block;
        width      : 4px;
        height     : 4px;
        border-bottom : 1px solid #000;
        border-right: 1px solid #000;
        transform  : rotate(45deg) translate(0px, 0px);
        position   : absolute;
        right      : -12px;
        bottom     : 2px;
    }

    &:after {
        content         : "";
        display         : block;
        width           : 1px;
        height          : 8px;
        background-color: black;
        transform       : rotate(0deg) translate(5px, 0px);
        right           : -5px;
        top             : 2px;
        position        : absolute;
    }
}

.review-helpful-report-wrapper {
    margin-top: 20px;
    display   : inline-block;
}

.review-sorting {
    margin-bottom: 15px;
}

.aw-ar-wrapper-fieldset {
    margin-top: 15px;
}

.input-text {
    width: 100%;
}

.admin__control-textarea {
    padding        : 10px;
    vertical-align : baseline;
    width          : 100%;
    box-sizing     : border-box;
    resize         : vertical;
}

.aw-ar-wrapper-fieldset {
    .label {
        font-size  : 14px;
        font-weight: 600;
        margin     : 0 0 8px;
        display    : inline-block;
        &:after {
            content  : '*';
            color    : #e02b27;
            font-size: 1.2rem;
            margin   : 0 0 0 5px;
        }
    }

    .primary,
    .secondary {
        display: inline-block;
    }

    .secondary {
        margin-left: 20px;
    }

}


.field {
    margin: 0 0 20px;
}
.form-block {
    background-color: #f8f8f8;
    border-top      : 1px solid #e4e4e4;
    padding         : 40px 0;
    textarea{
        width: 100%;
    }
    .aw-ar-wrapper-fieldset {
        max-width: 950px;
        padding  : 0 15px;
        margin   : 0 auto;

        .label {
            font-weight: 400;
            font-size  : 16px;
        }
    }

    .admin-field-value {
        font-size  : 20px;
        font-weight: 600;
        line-height: 1.1;
    }

    .field-custom-note {
        margin-left: 5px;
        color      : #303030;
        font-size  : 12px;
        font-weight: 400;
    }
    .option-select-cont {
        position: relative;
        // &::before {
        //     content       : "";
        //     position      : absolute;
        //     top           : 20px;
        //     display       : block;
        //     height        : 6px;
        //     width         : 6px;
        //     border-style  : solid;
        //     border-width  : 0 0 2px 2px;
        //     z-index       : 1;
        //     transform     : translate(-50%, -50%) rotate(-45deg);
        //     right         : 8px;
        //     pointer-events: none;
        // }

        .select {
            width      : 100%;
            padding    : 0 10px;
            height     : 40px;
            font-size  : 14px;
            font-weight: 500;
        }
    }

    .admin__control-textarea {
        min-height: 106px;
    }

    .actions-toolbar {
        text-align: right;
    }
}

/*new css*/
.block-content {
    padding-right: 70px;
    display: inline-block;
    width: 100%;
}

.review-item-info {
    display      : -ms-flexbox;
    display      : flex;
    margin-bottom: 50px;
}


.aw-ar__review-list-nav {
    // padding-left : 290px;
    margin-bottom: 45px;
    .CategoryPagination{
        min-height: 41px;
        @include mobile {
            display: flex;
        }
    }
}

.control-support-text {
    display    : inline-block;
    margin-left: 5px;
    color: #888;
}
.aw-ar__review-list-nav__inner{
    .left-column {
        vertical-align: top;
    }    
}

.left-column {
    display: inline-block;

    .review-nav-wrap {
        font-size      : 18px;
        display        : flex;
        justify-content: center;
        align-items    : center;
    }

    .right-button {
        margin-right: 15px;
        width       : 41px;
        height      : 41px;
        padding     : 0;

        &:before {
            content    : '';
            display    : block;
            margin     : 0 auto;
            width      : 10px;
            height     : 10px;
            border-top : 1px solid #000;
            border-left: 1px solid #000;
            transform  : rotate(-45deg);
            position   : absolute;
            top        : 14px;
            left       : 17px;
        }
        &:hover {
            &:before {
                border-top : 1px solid #fff;
                border-left: 1px solid #fff;
            }
        }
    }

    .left-button {
        margin-left: 15px;
        width      : 41px;
        height     : 41px;
        padding    : 0;

        &:before {
            content    : '';
            display    : block;
            margin     : 0 auto;
            width      : 10px;
            height     : 10px;
            border-top : 1px solid #000;
            border-left: 1px solid #000;
            transform  : rotate(135deg);
            position   : absolute;
            top        : 14px;
            left       : 12px;
        }
        &:focus,
        &:hover {
            &:before {
                border-top : 1px solid #fff;
                border-left: 1px solid #fff;
            }
        }
    }
}

.right-column {
    display    : inline-block;
    margin-left: 251px;

    .column-summary {
        float: none;
    }
}

.control-text {
    display       : inline-block;
    vertical-align: middle;
    text-align    : center;
    padding       : 0;
    font-weight: bold;
}

.not-editable {
    position          : absolute;
    font-weight       : 400;
    font-style        : normal;
    font-size         : 13px;
    color             : #333;
    left              : 0;
    top               : -15px;
    opacity           : 0;
    min-width         : 130px;
    -webkit-transition: all .5s ease;
    -moz-transition   : all .5s ease;
    -ms-transition    : all .5s ease;
    transition        : all .5s ease;
}

.reviews-section {
    &:hover {
        .not-editable {
            opacity: 1;
        }
    }
}

.ProductFeedback-ExpandableContentButton{
    @include desktop {
        display: none;
    }
}
.ProductFeedback-ExpandableContentContent_isContentExpanded{
    @include mobile {
        padding: 0;
    }
}
.ReviewSigninPopup-Content{
    input, textarea, select{
        width: 100%;
    }
    .MyAccountOverlay-Additional{
        section{
            flex-direction: column;
        }
        h4{
            text-align: center;
        }
    }
    button {
        width: 100%;
        margin-top: .6rem;
    }
}
.ReviewCreateAccountPopup-Content{
    input, textarea, select{
        width: 100%;
    }
    button {
        width: 100%;
        margin-top: .6rem;
    }
    .MyAccountOverlay-Additional{
        button {
            width: initial;
            height: initial;
            margin: 0;
            padding-left: .48rem;
        }
    }
    .MyAccountOverlay-Additional_state_signIn {
        section {
            flex-direction: row;
        }
    }
}
.ReviewForgotPasswordPopup-Content{
    input, textarea, select{
        width: 100%;
    }
    button {
        width: 100%;
        margin-top: .6rem;
    }
    .MyAccountOverlay-Additional{
        button {
            width: initial;
            height: initial;
            margin: 0;
            padding-left: .48rem;
        }
    }
}

@media only screen and (max-width: 1110px) {
    .right-column {
        margin-left: 15%;
    }
}

@media only screen and (max-width: 945px) {
    .right-column {
        margin-left: 10%;
    }
}


@media only screen and (max-width: 944px) {
    .ProductFeedback-Wrapper {
        .review-main {
            padding: 35px 20px 35px 20px;
        }

        .column-summary {
            width        : 100%;
            margin-bottom: 20px;
            margin-right : 0;
        }

        .rating-bar {
            width: 100%;
        }

        .recommend-customers {
            margin-top: 20px;
        }
    }

    .aw-ar__review-list-nav {
        text-align  : center;
        padding-left: 0;
        width       : 100%;
        float       : left;

        .left-column {
            display: block;
        }

        .right-column {
            margin-right: 0;
            margin-top  : 20px;
            margin-left : 0;
        }
    }

    .review-item-info {
        display: unset;
    }

    .block-content {
        padding : 0 20px;
    }

    .data-grid-filters-wrap {
        padding-left : 0;
        padding-right: 0;
        width        : 100%;
        text-align   : center;
    }

    .form-field-control {
        display: inline-block;
    }

    .review-sorting {
        .label {
            display: inline-block;
        }
    }

    .review-list {
        width         : 100%;
        float         : left;
        padding-bottom: 20px;
    }

    .actions-toolbar {
        text-align: center;
    }
}