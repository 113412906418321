/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --toggle-dot-size: 16px;
}

.Toggle {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;

    input {
        display: none;

        &:checked + label {
            background: var(--color-toggle-background-default);

            &::after {
                left: calc(100% - 2px);
                transform: translateX(-100%);
            }
        }
    }

    label {
        background: var(--color-toggle-background-unchecked);
        border-radius: 100px;
        cursor: pointer;
        display: block;
        height: 20px;
        position: relative;
        width: 36px;

        &::after {
            background: var(--color-toggle-dot-color);
            border-radius: 100%;
            content: '';
            height: var(--toggle-dot-size);
            left: 2px;
            position: absolute;
            top: 2px;
            transition: .3s;
            width: var(--toggle-dot-size);
        }
    }

    &-Label {
        color: var(--color-toggle-label-color);
        font-size: var(--color-toggle-label-font-size);
        font-weight: var(--color-toggle-label-font-weight);
        margin-left: 15px;
    }

    &_isGreen {
        input:checked + label {
            background: var(--color-toggle-background-green);
        }
    }

    &_disabled {
        opacity: .5;
    }
}
