/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.review-dashboard {
    @include desktop {
        grid-column: span 2;
    }
    .box-title {
        font-weight : bold;
        font-size   : 18px;
        display     : inline-block;
        margin: 0 0 1.0rem;
        @include mobile {
            font-size: 16px;
        }
    }
    .recent-review {
        margin-top: 50px;
        .box-title{
            margin-right: 20px;
        }
        .recent-table{
            margin-bottom: 0;
            width: 100%;
        }
    }
    .notifications{
        .notifications-text{
            margin-bottom: 12px;
        }
    }
}
